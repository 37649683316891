<template>
  <vca-card>
    <h2>{{ $t("email.edit.header") }}</h2>

    <EmailEdit />
  </vca-card>
</template>

<script>
import { mapGetters } from "vuex";
import EmailEdit from "@/components/emails/EmailEdit";
export default {
  name: "EmailsEdit",
  components: { EmailEdit },
  created() {
    this.$store.dispatch("emails/mailbox/get").then(() => {
      var mailbox = this.getMailbox(this.$route.params.id);
      if (mailbox != "draft") {
        this.notification({
          title: this.$t("messages.title.warn"),
          body: this.$t("messages.message.not_editable"),
          type: "warn",
        });
        this.$router.push("/emails");
      } else {
        this.$store.dispatch({
          type: "emails/mailbox/message/get",
          id: this.$route.params.id,
        });
      }
    });
  },
  computed: {
    ...mapGetters({
      getMailbox: "emails/mailbox/getMailbox",
    }),
  },
};
</script>
