<template>
  <div>
    <vca-column>
      <vca-card class="shadowed">
        <Receivers v-model="current" ref="receivers" />
      </vca-card>
      <div v-if="getReceiverGroup">
        <vca-card v-if="getReceiverGroup == 'event'" class="shadowed">
          <Group v-model="current" ref="group" />
        </vca-card>
        <vca-card v-else-if="getReceiverGroup == 'crew'" class="shadowed">
          <Membership v-model="current" />
        </vca-card>
        <vca-card
          v-if="
            getReceiverGroup == 'crew' ||
            (getReceiverGroup == 'event' &&
              current.recipient_group.event_id != '')
          "
          class="shadowed"
        >
          <Email v-model="current" ref="email" />
        </vca-card>
        <vca-card class="shadowed">
          <Tips />
        </vca-card>
      </div>
    </vca-column>

    <button class="vca-button" @click="update()" :disabled="isInvalid">
      {{ $t("button.save") }}
    </button>
    <div class="vca-center">
      <vca-column class="vca-center">
        <button
          class="vca-button-small vca-center"
          :disabled="isInvalid"
          @click="preview = true"
        >
          {{ $t("button.preview") }} & {{ $t("button.send") }}
        </button>
      </vca-column>
    </div>

    <vca-popup
      v-if="preview"
      :show="preview"
      :title="$t('email.preview.title', { 0: current.subject })"
      @close="preview = false"
    >
      <EmailPreview v-model="current" @send="send" />
    </vca-popup>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Receivers from "@/components/emails/form/Receivers";
import Email from "@/components/emails/form/Email";
import Tips from "@/components/emails/form/Tips";
import Group from "@/components/emails/form/event/Group";
import Membership from "@/components/emails/form/crew/Membership";
import EmailPreview from "@/components/emails/EmailPreview";
export default {
  name: "EmailEdit",
  components: { Receivers, Email, Tips, EmailPreview, Membership, Group },
  data() {
    return {
      preview: false,
    };
  },
  created() {
    this.$store.commit("emails/mailbox/message/user_id", this.user.id);
  },
  methods: {
    update() {
      if (this.isInvalid) {
        this.$refs.receivers.validate();
        if (this.getReceiverGroup == "event") {
          this.$refs.group.validate();
        }
        this.$refs.email.validate();
      } else {
        this.$store.dispatch("emails/mailbox/message/update").then(() => {
          this.$store.dispatch("emails/mailbox/get");
        });
      }
    },
    send() {
      if (this.isInvalid) {
        this.preview = false;
        this.$refs.receivers.validate();
        if (this.getReceiverGroup == "event") {
          this.$refs.group.validate();
        }
        this.$refs.email.validate();
      } else {
        this.$store
          .dispatch("emails/mailbox/message/update")
          .then((response) => {
            this.$store.commit("emails/mailbox/message/current", response);
            this.$store.dispatch("emails/mailbox/message/send").then(() => {
              this.$store.dispatch("emails/mailbox/get").then(() => {
                this.$router.push({
                  path: "/emails/outbox",
                });
              });
            });
          });
      }
    },
  },
  computed: {
    current: {
      set(value) {
        this.$store.commit("emails/mailbox/message/current", value);
      },
      get() {
        return this.$store.state.emails.mailbox.message.current;
      },
    },
    isInvalid() {
      return this.$v.current.$invalid;
    },
    getReceiverGroup() {
      return this.current ? this.current.recipient_group.type : "";
    },
    ...mapGetters({
      user: "user/current",
      validation: "emails/mailbox/message/validations",
    }),
  },
  validations() {
    return this.validation;
  },
};
</script>
